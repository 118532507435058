import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './CommunityPage.module.css';

const SectionHero = props => {
  const { category } = props;
  return (
    <div className={css.sectionHero}>
      <div className={css.heroContent}>
        <h1 className={css.heroTitle}>
          <FormattedMessage id="CommunityPage.heroTitle" />
        </h1>
        <p className={css.heroText}>
          <FormattedMessage id="CommunityPage.heroText" />
        </p>
        {category ? (
          <div className={css.heroBreadcrumbs}>
            <div className={css.heroBreadcrumb}>
              <NamedLink className={css.heroBreadcrumbLink} name="CommunityPage">
                <FormattedMessage id="CommunityPage.heroDefaultCategory" />
              </NamedLink>
            </div>
            <span className={css.heroBreadcrumbSeparator}>&gt;</span>
            <div className={css.heroBreadcrumb}>
              <div className={css.categoryBg} style={{ backgroundColor: category.color }} />
              <h3 className={css.heroBreadcrumbName}>{category.label}</h3>
            </div>
          </div>
        ) : (
          <div className={css.heroBreadcrumb}>
            <h3 className={css.heroBreadcrumbName}>
              <FormattedMessage id="CommunityPage.heroDefaultCategory" />
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionHero;
