import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../../util/types';
import { Modal } from '../../../components';
import { PostForm } from '../../../forms';

import css from './PostModal.module.css';

const PostModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitPost,
    submitPostInProgress,
    submitPostError,
    categories,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'PostModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="PostModal.title" />
      </p>
      <PostForm
        onSubmit={onSubmitPost}
        submitPostInProgress={submitPostInProgress}
        submitPostError={submitPostError}
        categories={categories}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

PostModal.defaultProps = {
  className: null,
  rootClassName: null,
  submitPostInProgress: false,
  submitPostError: null,
};

PostModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  submitPostInProgress: bool,
  submitPostError: propTypes.error,
};

export default injectIntl(PostModal);
