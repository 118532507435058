import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton, IconCheckmark } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './CommunityPage.module.css';
import classNames from 'classnames';

const SidebarLinks = props => {
  const { history, categories, currentSearchParams } = props;

  const onCategorySelect = category => {
    const searchParams = {
      pub_category: category,
    };
    history.push(
      createResourceLocatorString('CommunityPage', routeConfiguration(), {}, searchParams)
    );
  };

  const searchCategory = currentSearchParams?.pub_category;

  return (
    <ul className={css.sidebarCategories}>
      {categories.map(c => {
        const isCategorySelected = searchCategory === c.key;
        return (
          <li
            key={c.key}
            className={classNames(css.sidebarCategory, {
              [css.sidebarCategoryActive]: isCategorySelected,
            })}
            onClick={() => onCategorySelect(c.key)}
          >
            <div className={css.categoryBg} style={{ backgroundColor: c.color }} />
            <span className={css.labelWrapper}>
              {c.label}
              {isCategorySelected ? <IconCheckmark className={css.checkmark} size="small" /> : null}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

const Sidebar = props => {
  const { history, categories, currentSearchParams, onOpenPostModal } = props;
  return (
    <div className={css.sidebar}>
      <PrimaryButton className={css.sidebarButton} onClick={onOpenPostModal}>
        <FormattedMessage id="CommunityPage.startDiscussion" />
      </PrimaryButton>
      <SidebarLinks
        history={history}
        categories={categories}
        currentSearchParams={currentSearchParams}
      />
    </div>
  );
};

export default Sidebar;
